/**
 * Created by csi0n on 03/02/2017.
 */
(function () {
    let loading={
        on:function () {
            $("body").addClass("loading")
        },
        off:function () {
            $("body").removeClass("loading")
        }
    };
    $(document).pjax("a","#content",{fragment:"#content",timeout:3e3}).on("pjax:send",function () {
        loading.on();
    }).on("pjax:complete",function () {
        loading.off();
    }).ready(function () {
        WebFont.load({
            google: {
                families: ['Raleway','Droid Sans', 'Droid Serif'],
            },
            timeout:2000
        });
        new APlayer({
            element: document.getElementById('mp3'),
            narrow: false,
            autoplay: true,
            showlrc: false,
            mutex: true,
            theme: '#e6d0b2',
            preload: 'metadata',
            mode: 'circulation',
            music: {
                title: 'Preparation',
                author: 'Hans Zimmer/Richard Harvey',
                url: 'http://devtest.qiniudn.com/Preparation.mp3',
                pic: 'http://devtest.qiniudn.com/Preparation.jpg'
            }
        });
    });
    var hm = document.createElement("script");
    hm.src = "//hm.baidu.com/hm.js?3dac28c7b24e5c18fb8f68320087aaa8";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
})();